<template>
	<div class="header container">
		<v2-app-logo />

		<div class="header__wrapper">
			<v2-nav-menu />
			<v2-profile-button v-if="isAuth" />
		</div>

		<div class="header__menu" @click="toggleDrawer">
			<icon icon="v2-menu" color="transparent" />
		</div>

		<v2-app-header-menu />
	</div>
</template>

<script>
	import Icon from '@/components/ui/Icon';
	import V2AppLogo from '@/components/v2/header/V2AppLogo';
	import V2NavMenu from '@/components/v2/header/V2NavMenu';
	import V2ProfileButton from '@/components/v2/header/V2ProfileButton';
	import V2AppHeaderMenu from '@/components/v2/header/V2AppHeaderMenu.vue';

	export default {
		components: {
			V2AppLogo,
			V2NavMenu,
			V2ProfileButton,
			V2AppHeaderMenu,
			Icon
		},

		computed: {
			isAuth () {
				return this.$store.getters['auth/getIsAuthenticated'];
			}
		},

		methods: {
			toggleDrawer () {
				this.$store.commit('ui/toggleDrawerIsVisible');
			}
		}
	};
</script>

<style lang="scss" scoped>
.header {
  &__wrapper {
    display: flex;
  }

  &__menu {
    display: none;
    width: 23px;
    height: 18px;
  }

  @media (max-width: 1279px) {
    &__wrapper {
      display: none;
    }

    &__menu {
      display: flex;
    }
  }
}
</style>
