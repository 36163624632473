<template>
	<router-link
		to="/"
		class="header__logo"
	>
		<img
			src="@/assets/logo-horizontal-colour.svg"
			alt="Bridge128"
		/>
	</router-link>
</template>

<script>

	import routeParams from '@/mixins/routeParams';

	export default {
		mixins: [routeParams],
		props: {
			linkToHome: {
				type:Boolean,
				default: true
			},
			linkToLesson: {
				type:Boolean,
				default: false
			},
			size: {
				type: String,
				default: 'md'
			},
			colour: {
				type: String,
				default: 'colour'
			}
		},
		computed: {
			getWrapperComponent () {
				if (this.linkToHome ||
						this.linkToLesson) {
					return 'router-link';
				}
				return 'div';
			},
			getWrapperProps () {
				if (!this.linkToHome &&
						!this.linkToLesson) {
					return false;
				}
				if (this.linkToHome) {
					return {
						to: '/'
					};
				}
				if (this.getContext === 'teaching' ||
						this.getContext === 'admin') {
					return {
						to: `/${this.getContextSlug}/courses/${this.getCourseId}/lessons/${this.getLessonId}`
					};
				}
				return {
					to: `/my-courses/${this.getCourseId}/lessons/${this.getLessonId}`
				};
			},
			getSrc () {
				if (this.colour === 'white')  {
					return require('@/assets/logo-horizontal-white.svg');
				}
				return require('@/assets/logo-horizontal-colour.svg');
			}
		}
	};
</script>

<style lang="scss" scoped>
	.header__logo {
		cursor:pointer;
		user-select:none;
		display:block;
    width: 153px;
    height: 32px;
		img {
			width: 100%;
		}
	}
</style>
